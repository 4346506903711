<template>
  <div v-if="!isLoading">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{
            isSuccess
              ? 'Pagamento realizado com sucesso!'
              : 'Houve um erro no processamento do seu pagamento'
          }}
        </h2>
      </div>
    </div>
    <b-row align-h="center">
      <b-button variant="primary" @click="goToHomePage()"
        >Ir para a Página Inicial</b-button
      >
    </b-row>
  </div>
  <b-row v-else align-h="center" class="mt-4">
    <h3 class="mr-1">Finalizando os últimos detalhes</h3>
    <b-spinner></b-spinner>
  </b-row>
</template>

<script>
/* eslint-disable global-require */
import {
  BSpinner,
  BLink,
  BForm,
  BFormInput,
  BButton,
  BImg,
  BRow,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import RoleEnum from '@/utils/roles'
import softwarePartnerStoreModule from '@/views/sections/software-partners/softwarePartnerStoreModule'
import labStoreModule from '@/views/sections/labs/labStoreModule'
import opticStoreModule from '@/views/sections/optics/opticStoreModule'
import userStoreModule from '@/views/admin/users/userStoreModule'
import { onUnmounted } from 'vue-demi'

export default {
  components: {
    VuexyLogo,
    BLink,
    BForm,
    BButton,
    BFormInput,
    BImg,
    BRow,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      isSuccess: false,
      downImg: require('@/assets/images/pages/coming-soon.svg'),
    }
  },
  methods: {
    goToHomePage() {
      this.$router.push('/')
    },
  },
  async created() {
    this.isLoading = true
    if (
      this.$route.query.ss === 'true' &&
      this.$route.query.p &&
      this.$route.query.u &&
      this.$route.query.id &&
      this.$route.query.r
    ) {
      const path = this.$route.query.p
      const u = this.$route.query.u
      const role = parseInt(this.$route.query.r)
      const id = this.$route.query.id

      let setPath = ''
      let getPath = ''

      switch (role) {
        case RoleEnum.SOFTWARE_ADMIN:
          setPath = 'software-partner/setSoftwarePartner'
          getPath = 'software-partner/fetchOneSoftwarePartner'
          break
        case RoleEnum.LABORATORY_ADMIN:
          setPath = 'laboratory/setLaboratory'
          getPath = 'laboratory/fetchOneLab'
          break
        case RoleEnum.OPTIC_ADMIN:
          setPath = 'optic/setOptic'
          getPath = 'optic/fetchOneOptic'
          break
      }

      const entityData = await this.$store.dispatch(getPath, path)
      entityData.status = 'active'

      //   Update entity
      await store.dispatch(setPath, {
        id: id,
        data: entityData,
        parent: path,
      })

      const user = await this.$store.dispatch('user/fetchOneUser', u)
      user.status = 'active'

      //   Update user
      await store.dispatch('auth/updateUserData', {
        id: u,
        data: user,
      })

      this.isSuccess = true
    }
    this.isLoading = false
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'user'
    const SOFTWARE_PARTNER_APP_STORE_MODULE_NAME = 'software-partner'
    const LAB_APP_STORE_MODULE_NAME = 'laboratory'
    const OPTIC_APP_STORE_MODULE_NAME = 'optic'

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }
    if (!store.hasModule(SOFTWARE_PARTNER_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        SOFTWARE_PARTNER_APP_STORE_MODULE_NAME,
        softwarePartnerStoreModule,
      )
    }

    if (!store.hasModule(LAB_APP_STORE_MODULE_NAME)) {
      store.registerModule(LAB_APP_STORE_MODULE_NAME, labStoreModule)
    }

    if (!store.hasModule(OPTIC_APP_STORE_MODULE_NAME)) {
      store.registerModule(OPTIC_APP_STORE_MODULE_NAME, opticStoreModule)
    }
    onUnmounted(() => {
      store.hasModule(USER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      store.hasModule(SOFTWARE_PARTNER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SOFTWARE_PARTNER_APP_STORE_MODULE_NAME)

      store.hasModule(LAB_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(LAB_APP_STORE_MODULE_NAME)

      store.hasModule(OPTIC_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(OPTIC_APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>

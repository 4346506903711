import { QueryConstraint } from 'firebase/firestore'
import { Store } from 'vuex'
import { CustomerController } from '../../../controllers/customer.controller'

import UserController from '../../../controllers/user.controller'
import UserEntity from '../../../entities/user.entity'

const userController = new UserController()

export default {
  namespaced: true,
  state: {
    users: [],
    loading: false,
    loadingUsers: false,
  },
  getters: {},
  mutations: {
    /**
     * Sets the `loading` property.
     *
     * @param {Object} state the user state.
     * @param {boolean} data the loading status.
     */
    SET_LOADING(state, data) {
      state.loading = data
    },

    /**
     * Sets the `loadingUsers` property.
     *
     * @param {Object} state the user state.
     * @param {boolean} data the loading status.
     */
    SET_LOADING_LABS(state, data) {
      state.loadingUsers = data
    },

    /**
     * Sets the `users` property.
     *
     * @param {Object} state the user state.
     * @param {UserEntity[]} data an array of users.
     */
    SET_USERS(state, data) {
      state.users = data
    },
  },
  actions: {
    /**
     * Loads and listens for users.
     *
     * @param {Store} store the vuex store.
     */
    async fetchUsers({ commit }) {
      commit('SET_LOADING', true)

      try {
        userController.listenMultiple((users) => {
          commit('SET_USERS', users)
        })
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Get an user from the database.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the user id.
     * @returns an user entity.
     */
    async fetchOneUser({ state, commit }, payload) {
      commit('SET_LOADING', true)

      try {
        const user =
          state.users.find((u) => u.id === payload) ??
          (await userController.getById(payload))

        if (!user) {
          throw new Error('user-not-found')
        }

        return user
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Deletes a user according to the given id.
     *
     * @param {Object} payload the action payload.
     * @param {string} payload.path the document path.
     * @param {string} payload.userId the user id.
     */
    async deleteUser(_, payload) {
      await userController.softDelete(payload.path, payload.userId)
    },

    /**
     * Deletes a customer according to the given id.
     *
     * @param {string} payload the customer id.
     */
    async deleteCustomer(_, payload) {
      await new CustomerController().delete(payload)
    },

    /**
     * Gets a customer from the database according to the given id.
     *
     * @param {string} payload the customer id.
     * @returns the found customer entity.
     */
    async fetchOneCustomer(_, payload) {
      try {
        const customer = await new CustomerController().getOne(payload)

        if (!customer) {
          throw new Error('customer-not-found')
        }

        return customer
      } catch (e) {
        throw e
      }
    },

    /**
     * Restores an user from the soft delete.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the document path.
     */
    async restoreUser({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        await userController.restore(payload)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async updateUserInfo({ commit, dispatch, state }, payload) {
      try {
        commit('SET_LOADING', true)
        await new UserController().update(payload.id, payload.data)
      } catch (e) {
        console.error(e)
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}

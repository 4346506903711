import Entity from './base.entity'

/**
 * Class responsible for keeping the customer properties and methods.
 */
export class CustomerEntity extends Entity {
  constructor({
    id,
    email,
    stripeId,
    stripeLink,
    created_at,
    updated_at,
  } = {}) {
    super({ id, created_at, updated_at })

    /**
     * Property that defines the customer email.
     *
     * @type {string}
     */
    this.email = email

    /**
     * Property that defines the customer id into the stripe system.
     *
     * @type {string}
     */
    this.stripeId = stripeId

    /**
     * Property that defines an URL to the customer dashboard.
     *
     * @type {string}
     */
    this.stripeLink = stripeLink
  }

  /**
   * Converts the firestore data to the customer model.
   *
   * @param {CustomerEntity} data the firestore customer data.
   */
  static fromFirestore(data) {
    return new CustomerEntity(data)
  }
}
